import AZTEK from "../assets/newsImages/aztek.png";
import HKTM from "../assets/newsImages/hktm.jpg";
import BAREM from "../assets/newsImages/barem.jpg";
import OBASE from "../assets/newsImages/obase.png";
import KOROPLAST from "../assets/newsImages/koroplast.jpg";
import KILER from "../assets/newsImages/kiler.jpg";
import RUBENIS from "../assets/newsImages/rubenis.png";
import ALFAS from "../assets/newsImages/alfas.png";
import SANICA from "../assets/newsImages/sanica.png";
import OZSU from "../assets/newsImages/ozsu.png";

export const newsCardData = [
  {
    id: 3,
    companyName: "Özsu Balık Üretim A.Ş.",
    companyLogo: OZSU,
    isNew: true,
  },
  {
    id: 4,
    companyName: "Sanica Isı Sanayi A.Ş.",
    companyLogo: SANICA,
    isNew: true,
  },
  {
    id: 5,
    companyName: "Alfa Solar Enerji",
    companyLogo: ALFAS,
    isNew: true,
  },
  {
    id: 6,
    companyName: "Aztek Teknoloji",
    companyLogo: AZTEK,
    isNew: false,
  },
  {
    id: 7,
    companyName:
      "Hidropar Hareket Kontrol Teknolojileri Merkezi Sanayi ve Ticaret A.Ş.",
    companyLogo: HKTM,
    isNew: false,
  },
  {
    id: 8,
    companyName: "Barem Ambalaj",
    companyLogo: BAREM,
    isNew: false,
  },
  {
    id: 9,
    companyName: "Obase Bilgisayar",
    companyLogo: OBASE,
    isNew: false,
  },
  {
    id: 10,
    companyName:
      "Koroplast Temizlik Ambalaj Ürünleri Sanayi ve Dış Ticaret A.Ş.",
    companyLogo: KOROPLAST,
    isNew: false,
  },
  {
    id: 11,
    companyName: "Kiler Holding A.Ş.",
    companyLogo: KILER,
    isNew: false,
  },
  {
    id: 12,
    companyName: "Rubenis Tekstil Sanayi Ticaret A.Ş.",
    companyLogo: RUBENIS,
    isNew: false,
  },
];
