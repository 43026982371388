import React from "react";
import "./index.css";
import { Divider } from "antd";
import {
  LinkedinFilled,
  GithubOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

export default function About({ isDark }) {
  return (
    <div className="aboutDivWrapper">
      <h1 className={isDark ? `titleDark` : `titleLight`}>Bilgilendirme</h1>
      <p>
        Burada yer alan hesaplama sonuçları herhangi bir yatırım tavsiyesi,
        yatırım önerisi içermemektir. Buradaki hesaplama sonuçları katıldığınız
        halka arzın her gün tavan gitmesi ihtimali baz alınarak ortaya
        çıkarılmıs değerlerdir. Bunlar sadece varsayım, istatiksel
        yaklaşımlardır. Hiçbiri yatırım tavsiyesi, yönlendirmesi değildir.
      </p>
      <Divider className="aboutDivider" />
      <div className="contactWrapper">
        <p>Geliştirici: Ceyhun Gülbaş</p>
        <div
          className={`contactDiv ${
            isDark ? `contactDivDark` : `contactDivLight`
          }`}
        >
          <a
            href="https://www.linkedin.com/in/ceyhun-gulbas/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinFilled />
          </a>
          <a
            href="https://github.com/ceyhungulbas"
            target="_blank"
            rel="noreferrer"
          >
            <GithubOutlined />
          </a>
          <a
            href="https://www.instagram.com/ceyhungulbas/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramOutlined />
          </a>
          <a
            href="https://twitter.com/CeyhunGulbas"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterOutlined />
          </a>
        </div>
      </div>
    </div>
  );
}
