import React from "react";
import "./index.css";

export default function CalculatorInfo({ shareAmount, shareCost, accAmount }) {
  return (
    <div className="infoWrapper">
      <span className="info">Lot: {shareAmount}</span>
      <span className="info">
        Maliyet: {`₺${Number(shareCost).toFixed(2)}`}
      </span>
      <span className="info" title="Lot x Maliyet x Hesap Sayısı">
        Giriş Ücreti: {`₺${(shareCost * shareAmount * accAmount).toFixed(2)}`}
      </span>
    </div>
  );
}
