import React from "react";
import "./index.css";

export default function CalculatorTitle() {
  return (
    <div className="headerWrapper">
      <span className="header" title="Tarih">
        Tarih
      </span>
      <span className="header" title="Maliyet x 1.10">
        Fiyat
      </span>
      <span
        className="header"
        title="Lot Sayısı x Fiyat - Lot Sayısı x Maliyet"
      >
        Kar
      </span>
      <span className="header" title="Kar x Katılımcı Sayısı">
        Toplam Kar
      </span>
      <span
        className="header"
        title="Giriş Ücreti x Katılımcı Sayısı + Toplam Kar"
      >
        Toplam Dönüş
      </span>
    </div>
  );
}
