import React from "react";
import { Image } from "antd";
import "./index.css";
import Dictionary from "../Dictionary";
import Example1 from "../../assets/example1.png";
import Example2 from "../../assets/example2.png";

export default function Help({ isDark }) {
  return (
    <div className="helpWrapper">
      <h1 className={`helpH1 ${isDark ? `titleDark` : `titleLight`}`}>
        Nedir?
      </h1>
      <p>
        Bu hesaplama, yeni halka arz edilmiş bir şirketin her gün tavan gitmesi
        baz alınarak sizin yaklaşık ne kadar kazanacağınızı göstermektedir.
      </p>
      <h3 className={`dictionaryh3 ${isDark ? `titleDark` : `titleLight`}`}>
        Sitedeki Terimlerin Anlamı
      </h3>
      <div className="dictionaryWrapper">
        <div>
          <Dictionary
            title={"- Lot Sayısı: "}
            description={
              "Borsada işlem birimidir. 1 Lot, 1 Adet Hisse Senedi olarak geçer."
            }
          />
          <Dictionary
            title={"- Maliyet: "}
            description={
              "Hisse senedini aldığınızdaki para olarak karşılığıdır."
            }
          />
          <Dictionary
            title={"- Hesap Sayısı: "}
            description={
              "Kaç tane hesapla halka arza katıldığınızı belirtirsiniz."
            }
          />
          <Dictionary
            title={"- Başlangıç Tarihi: "}
            description={
              "Halka arzın işleme başlayacağı tarihi doldurabilirsiniz."
            }
          />
          <Dictionary
            title={"- Giriş Ücreti: "}
            description={
              "Lot sayısının maliyet ve katıldığınız hesap sayısı ile çarpımıdır yani toplam yatırdığınız paradır."
            }
          />
          <Dictionary
            title={"- Fiyat: "}
            description={
              "Hissenin tavan gitmesi durumunda maliyetiniz %10 ile çarpımıdır."
            }
          />
          <Dictionary
            title={"- Kar: "}
            description={
              "Bir günde ettiğiniz ve tek hesaptan katıldığınız şartlar altındaki kazancınızdır."
            }
          />
          <Dictionary
            title={"- Toplam Kar: "}
            description={
              "Karın, katıldığınız hesapların sayısı ile çarpımıdır."
            }
          />
          <Dictionary
            title={"- Toplam Dönüş: "}
            description={"Giriş ücretinin toplam kar ile toplamıdır."}
          />
        </div>
      </div>
      <div className="exampleImageWrapper">
        <div>
          <Image
            src={Example1}
            className={`exampleImage ${isDark ? `exampleImageDark` : ``}`}
            alt="Example about inputs"
          />
        </div>
        <div>
          <Image
            src={Example2}
            className={`exampleImage ${isDark ? `exampleImageDark` : ``}`}
            alt="Example about results"
          />
        </div>
      </div>
    </div>
  );
}
