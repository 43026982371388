import AZTEK from "../assets/newsImages/aztek.png";
import HKTM from "../assets/newsImages/hktm.jpg";
import BAREM from "../assets/newsImages/barem.jpg";
import OBASE from "../assets/newsImages/obase.png";
import KOROPLAST from "../assets/newsImages/koroplast.jpg";
import KILER from "../assets/newsImages/kiler.jpg";
import RUBENIS from "../assets/newsImages/rubenis.png";
import ALFAS from "../assets/newsImages/alfas.png";
import SANICA from "../assets/newsImages/sanica.png";
import OZSU from "../assets/newsImages/ozsu.png";

export const newsCompanyData = [
  {
    id: 3,
    companyName: "Özsu Balık Üretim A.Ş.",
    talepTarihi: "22-23 Kasım 2022",
    lotAdedi: "20.000.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 7.69",
    dagitimTuru: "Eşit Dağıtım",
    konsorsiyumLideri: "Gedik Yatırım",
    iskontoOrani: "%20",
    islemKodu: "#OZSUB",
    companyLogo: OZSU,
  },
  {
    id: 4,
    companyName: "Sanica Isı Sanayi A.Ş.",
    talepTarihi: "15-18 Kasım 2022",
    lotAdedi: "40.000.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 20.03",
    dagitimTuru: "Oransal Dağıtım",
    konsorsiyumLideri: "Gedik ve İntegral",
    iskontoOrani: "%20",
    islemKodu: "#SNICA",
    companyLogo: SANICA,
  },
  {
    id: 5,
    companyName: "Alfa Solar Enerji",
    talepTarihi: "9-11 Kasım 2022",
    lotAdedi: "9.200.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 58.50",
    dagitimTuru: "Oransal Dağıtım",
    konsorsiyumLideri: "Deniz Yatırım",
    iskontoOrani: "%20",
    islemKodu: "#ALFAS",
    companyLogo: ALFAS,
  },
  {
    id: 6,
    companyName: "Aztek Teknoloji",
    talepTarihi: "10-12 Ağustos 2022",
    lotAdedi: "25.000.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 10.90",
    dagitimTuru: "Oransal Dağıtım",
    konsorsiyumLideri: "Deniz Yatırım",
    iskontoOrani: "%20",
    islemKodu: "#AZTEK",
    companyLogo: AZTEK,
  },
  {
    id: 7,
    companyName:
      "Hidropar Hareket Kontrol Teknolojileri Merkezi Sanayi ve Ticaret A.Ş.",
    talepTarihi: "10-12 Ağustos 2022",
    lotAdedi: "12.000.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 11.00",
    dagitimTuru: "Eşit Dağıtım (Bireysel)",
    konsorsiyumLideri: "A1 Capital",
    iskontoOrani: "%20",
    islemKodu: "#HKTM",
    companyLogo: HKTM,
  },
  {
    id: 8,
    companyName: "Barem Ambalaj",
    talepTarihi: "03-04 Ağustos 2022",
    lotAdedi: "10.500.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 36.00",
    dagitimTuru: "Eşit Dağıtım",
    konsorsiyumLideri: "İnfo Yatırım",
    iskontoOrani: "%21",
    islemKodu: "#BARMA",
    companyLogo: BAREM,
  },
  {
    id: 9,
    companyName: "Obase Bilgisayar",
    talepTarihi: "28-29 Temmuz 2022",
    lotAdedi: "14.000.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 10.50",
    dagitimTuru: "Eşit Dağıtım",
    konsorsiyumLideri: "Ziraat Yatırım",
    iskontoOrani: "%20.8",
    islemKodu: "#OBASE",
    companyLogo: OBASE,
  },
  {
    id: 10,
    companyName:
      "Koroplast Temizlik Ambalaj Ürünleri Sanayi ve Dış Ticaret A.Ş.",
    talepTarihi: "20-22 Temmuz 2022",
    lotAdedi: "66.800.000 Lot",
    ekLotSatis: "11.700.000 Lot Ek Satış",
    lotFiyati: "₺ 4.68",
    dagitimTuru: "Oransal Dağıtım",
    konsorsiyumLideri: "YapıKredi Yatırım",
    iskontoOrani: "%25",
    islemKodu: "#KRPLS",
    companyLogo: KOROPLAST,
  },
  {
    id: 11,
    companyName: "Kiler Holding A.Ş.",
    talepTarihi: "30 Haziran - 01 Temmuz 2022",
    lotAdedi: "97.500.000 Lot",
    ekLotSatis: "19.500.000 Lot Ek Satış",
    lotFiyati: "₺ 7.10",
    dagitimTuru: "Eşit Dağıtım (Bireysel)",
    konsorsiyumLideri: "Tacirler Menkul",
    iskontoOrani: "%12.5",
    islemKodu: "#KLRHO",
    companyLogo: KILER,
  },
  {
    id: 12,
    companyName: "Rubenis Tekstil Sanayi Ticaret A.Ş.",
    talepTarihi: "29-30 Haziran 2022",
    lotAdedi: "24.500.000 Lot",
    ekLotSatis: false,
    lotFiyati: "₺ 12.50",
    dagitimTuru: "Eşit Dağıtım (Bireysel)",
    konsorsiyumLideri: "Ahlatçı Yatırım",
    iskontoOrani: "%20",
    islemKodu: "#RUBNS",
    companyLogo: RUBENIS,
  },
];
