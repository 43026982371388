import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./components/About";
import Calculator from "./components/Calculator";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ThemeContext } from "./themes/themes";
import BackgroundLogo from "./components/BackgroundLogo";
import Help from "./components/Help";
import NewsContainer from "./containers/News";
import NewsCompanyContainer from "./containers/NewsCompanyContainer";

function App() {
  const [{ theme, isDark }] = useContext(ThemeContext);

  return (
    <div
      className="App"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <Header isDark={isDark} />
      <BackgroundLogo />
      <Routes>
        <Route path="/" element={<Calculator isDark={isDark} />} />
        <Route path="/help" element={<Help isDark={isDark} />} />
        <Route path="/news" element={<NewsContainer isDark={isDark} />} />
        <Route
          path="/news/:companyId"
          element={<NewsCompanyContainer isDark={isDark} />}
        />
        <Route path="/about" element={<About isDark={isDark} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
