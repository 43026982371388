import React from "react";
import { Col, Card, Badge } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";

export default function NewsCard({
  companyName,
  companyLogo,
  id,
  isNew = false,
}) {
  const navigate = useNavigate();

  const navigateToNews = (newsId) => {
    navigate(`/news/${newsId}`);
  };

  return (
    <>
      {isNew ? (
        <Col xs={24} sm={12} md={8} lg={6} className="newsCardCol">
          <Badge.Ribbon text="Yeni" placement="start" color="red">
            <Card
              className="newsCard"
              title={
                <span className="newsCardTitle" title={companyName}>
                  {companyName}
                </span>
              }
              hoverable={true}
              bordered={false}
              onClick={() => navigateToNews(id)}
            >
              <img src={companyLogo} alt={companyName} className="newsImage" />
            </Card>
          </Badge.Ribbon>
        </Col>
      ) : (
        <Col xs={24} sm={12} md={8} lg={6} className="newsCardCol">
          <Card
            className="newsCard"
            title={
              <span className="newsCardTitle" title={companyName}>
                {companyName}
              </span>
            }
            hoverable={true}
            bordered={false}
            onClick={() => navigateToNews(id)}
          >
            <img src={companyLogo} alt={companyName} className="newsImage" />
          </Card>
        </Col>
      )}
    </>
  );
}
