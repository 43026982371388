import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ThemeContext } from "../../themes/themes";

export default function Header() {
  const [{ isDark }, toggleTheme] = useContext(ThemeContext);
  const [navExpanded, setNavExpanded] = useState(false);

  const closeNav = () => {
    setNavExpanded(false);
  };

  return (
    <Navbar
      bg="light"
      expand="lg"
      className={isDark ? "darkNavbar" : "lightNavbar"}
      onToggle={setNavExpanded}
      expanded={navExpanded}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={isDark ? "darkNavbarCollapse" : "lightNavbarCollapse"}
      >
        <Nav className="mr-auto" onClick={closeNav}>
          <Nav.Link
            as={Link}
            to="/"
            className={isDark ? "darkLink" : "lightLink"}
          >
            Ana Sayfa
          </Nav.Link>
          <NavDropdown.Divider className={isDark ? "darkHr" : "lightHr"} />
          <Nav.Link
            as={Link}
            to="/help"
            className={isDark ? "darkLink" : "lightLink"}
          >
            Nedir?
          </Nav.Link>
          <NavDropdown.Divider className={isDark ? "darkHr" : "lightHr"} />
          <Nav.Link
            as={Link}
            to="/news"
            className={isDark ? "darkLink" : "lightLink"}
          >
            Haberler
          </Nav.Link>
          <NavDropdown.Divider className={isDark ? "darkHr" : "lightHr"} />
          <Nav.Link
            as={Link}
            to="/about"
            className={isDark ? "darkLink" : "lightLink"}
          >
            Bilgilendirme
          </Nav.Link>
          <button onClick={toggleTheme} className="themeButton">
            {isDark ? "☀️" : "🌑"}
          </button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
