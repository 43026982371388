import React from "react";
import NewsCard from "../../components/NewsCard";
import { newsCardData } from "../../newsData/newsCardData";
import "./index.css";

export default function NewsContainer({ isDark }) {
  return (
    <div className="newsContainer">
      <h1 className={`newsH1 ${isDark ? `titleDark` : `titleLight`}`}>
        Haberler
      </h1>
      <div className="newsCardWrapper">
        {newsCardData.map((info) => (
          <NewsCard
            key={info.companyName}
            companyName={info.companyName}
            companyLogo={info.companyLogo}
            id={info.id}
            isNew={info.isNew}
          />
        ))}
      </div>
    </div>
  );
}
