import React from "react";
import "./index.css";

export default function Dictionary({ title, description }) {
  return (
    <div className="dictionary">
      <span className="dictionaryTitle">{title}</span>
      <span className="dictionaryDescription">{description}</span>
      <hr className="dictionaryHr" />
    </div>
  );
}
