import React, { useEffect, useState } from "react";
import "./index.css";
import { DatePicker, Tooltip } from "antd";
import CalculatorTitle from "../CalculatorTitle";
import CalculatorInfo from "../CalculatorInfo";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ShareAltOutlined, CheckOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Calculator({ isDark }) {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [copyState, setCopyState] = useState(false);

  const [shareName, setShareName] = useState("");
  const [shareAmount, setShareAmount] = useState();
  const [shareCost, setShareCost] = useState();
  const [accAmount, setAccAmount] = useState();
  const [startDate, setStartDate] = useState(new Date());

  const handleNameInput = (e) => {
    setShareName(e.target.value);
  };
  const handleShareInput = (e) => {
    setShareAmount(e.target.value);
  };
  const handleCostInput = (e) => {
    setShareCost(e.target.value);
  };
  const handleAccNumberInput = (e) => {
    setAccAmount(e.target.value);
  };

  const handleStartDate = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleCopy = () => {
    setCopyState(true);
    setTimeout(() => setCopyState(false), 3000);
  };

  useEffect(() => {
    if (shareName.length && shareAmount && shareCost && accAmount) {
      navigate({
        pathname: "",
        search: createSearchParams({
          shareName: shareName,
          shareAmount: shareAmount,
          shareCost: shareCost,
          accAmount: accAmount,
        }).toString(),
      });
    } else {
      navigate("");
    }
  }, [navigate, shareName, shareAmount, shareCost, accAmount]);

  useEffect(() => {
    if (search.length) {
      const splitSearch = search.split("&");
      //eslint-disable-next-line
      splitSearch.map((word) => {
        const information = word.substring(word.indexOf("=") + 1);

        if (word.includes("shareName")) {
          setShareName(information);
        } else if (word.includes("shareAmount")) {
          setShareAmount(information);
        } else if (word.includes("shareCost")) {
          setShareCost(information);
        } else if (word.includes("accAmount")) {
          setAccAmount(information);
        }
      });
    }
  }, [search]);

  const Result = () => {
    const allDates = [];

    const my_date = new Date(startDate);

    for (let i = 0; i < 13; i++) {
      const newDate = new Date(
        my_date.setDate(my_date.getDate() + 1)
      )?.toLocaleDateString("en-GB");

      if (my_date.getDay() === 6 || my_date.getDay() === 0) {
        continue;
      } else {
        allDates.push(newDate);
      }
    }

    if (allDates.length) {
      const firstSelectedDayAsString = new Date(startDate)?.toLocaleDateString(
        "en-GB"
      );

      const firstSelectedDay = new Date(startDate).getDay();
      if (firstSelectedDay === 6 || firstSelectedDay === 0) {
      } else {
        allDates.unshift(firstSelectedDayAsString);
      }
    }

    const shareCostProfit = [];

    let oneDayProfit = shareCost * 1.1;
    shareCostProfit.unshift(oneDayProfit.toFixed(2));

    for (let j = 0; j < 13; j++) {
      oneDayProfit *= 1.1;
      shareCostProfit.push(oneDayProfit.toFixed(2));
    }

    return allDates.map((date, i) => {
      const profitWithoutShareAmount = (
        shareAmount * shareCostProfit[i] -
        shareAmount * shareCost
      ).toFixed(2);

      const profitWithShareAmount = (
        profitWithoutShareAmount * accAmount
      ).toFixed(2);

      const openingCost = shareCost * shareAmount;

      const totalReturn = (
        openingCost * accAmount +
        parseFloat(profitWithShareAmount)
      ).toFixed(2);

      return (
        <div
          key={i}
          className={`resultWrapper ${isDark ? "darkResult" : "lightResult"}`}
        >
          <span className="result">
            {date === "Invalid Date" ? `${i + 1}. Gün` : date}
          </span>
          <span className="result">₺ {shareCostProfit[i]}</span>
          <span className="result">₺ {profitWithoutShareAmount}</span>
          <span className="result">₺ {profitWithShareAmount}</span>
          <span className="result">₺ {totalReturn}</span>
        </div>
      );
    });
  };

  return (
    <div className="calculator">
      <h1 className={isDark ? `titleDark` : `titleLight`}>
        Halka Arz Hesaplama
      </h1>
      <div className="inputWrapper">
        <div className="inputDiv">
          <span>Hisse Kodu</span>
          <input
            placeholder="Hisse Kodu"
            onChange={handleNameInput}
            value={shareName}
            type="text"
          />
        </div>

        <div className="inputDiv">
          <span>Lot sayısı</span>
          <input
            placeholder="Lot sayısı"
            onChange={handleShareInput}
            value={shareAmount}
            type="number"
            min="0"
          />
        </div>

        <div className="inputDiv">
          <span>Maliyet</span>
          <input
            placeholder="Maliyet"
            onChange={handleCostInput}
            value={shareCost}
            type="number"
            min="0"
          />
        </div>

        <div className="inputDiv">
          <span>Hesap sayısı</span>
          <input
            placeholder="Hesap sayısı"
            onChange={handleAccNumberInput}
            value={accAmount}
            type="number"
            min="0"
          />
        </div>

        <div className="inputDiv">
          <span>Başlangıç Tarihi</span>
          <DatePicker
            onChange={handleStartDate}
            format="MM/DD/YYYY"
            className="dateInput"
            placeholder="Başlangıç Tarihi"
          />
        </div>
      </div>
      <div className="calculator calculatorResult">
        <h1
          className={
            isDark
              ? `titleCalendar titleCalendarDark`
              : `titleCalendar titleCalendarLight`
          }
        >
          {`${shareName.length ? shareName : ""} Hisse Takvimi `}

          <Tooltip title="Paylaş!" color={"#576ec0"} className="tooltipShare">
            <CopyToClipboard
              text={`Bu halka arzı kontrol et!\n\n${window.location.href}`}
              onCopy={handleCopy}
            >
              {copyState ? (
                <CheckOutlined className="copiedSVG" />
              ) : (
                <ShareAltOutlined className="copiedSVG" />
              )}
            </CopyToClipboard>
          </Tooltip>
        </h1>

        {shareAmount && shareCost && accAmount ? (
          <>
            <CalculatorInfo
              shareAmount={shareAmount}
              shareCost={shareCost}
              accAmount={accAmount}
            />
            <CalculatorTitle />
            <Result />
          </>
        ) : (
          <p className="continueP">
            Devam etmek için lütfen boş alanları doldurun.
          </p>
        )}
      </div>
    </div>
  );
}
