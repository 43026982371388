import React from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import { newsCompanyData } from "../../newsData/newsCompanyData";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

export default function NewsCompanyContainer({ isDark }) {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const checkCompany = (company) => {
    return company?.id === Number(companyId);
  };

  const handleGoBack = () => {
    navigate(`../news`);
  };

  const selectedCompany = newsCompanyData.filter(checkCompany);

  return (
    <div>
      <div className="companyHeader">
        <h1 className={`companyH1 ${isDark ? `titleDark` : `titleLight`}`}>
          {selectedCompany[0]?.companyName}
        </h1>
        <Tooltip title="Geri dön" color={"#576ec0"}>
          <ArrowLeftOutlined
            className={`goBackButton ${
              isDark ? `goBackButtonDark` : `goBackButtonLight`
            }`}
            onClick={handleGoBack}
          />
        </Tooltip>
      </div>
      <div className="companyDataWrapper">
        <span>İşlem kodu: {selectedCompany?.[0]?.islemKodu}</span>
        <span>Talep Tarihi: {selectedCompany?.[0]?.talepTarihi}</span>
        <span>Lot Adeti: {selectedCompany?.[0]?.lotAdedi}</span>
        {selectedCompany?.[0]?.ekLotSatis ? (
          <span>Ek Lot Satış: {selectedCompany?.[0]?.ekLotSatis}</span>
        ) : null}
        <span>Lot Fiyatı: {selectedCompany?.[0]?.lotFiyati}</span>
        <span>Dağıtım Türü: {selectedCompany?.[0]?.dagitimTuru}</span>
        <span>
          Konsorsiyum Lideri: {selectedCompany?.[0]?.konsorsiyumLideri}
        </span>
        <span>İskonto oranı: {selectedCompany?.[0]?.iskontoOrani}</span>
      </div>
      <div className="companyDataImageWrapper">
        <img
          src={selectedCompany?.[0]?.companyLogo}
          alt={`${selectedCompany?.[0]?.companyName} logo`}
          className="companyDataImage"
        />
      </div>
    </div>
  );
}
