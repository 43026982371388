import React from "react";
import Logo from "../../assets/logo.png";
import "./index.css";

export default function BackgroundLogo() {
  return (
    <div className="logoWrapper">
      <img src={Logo} alt="Logo" className="logo" />
    </div>
  );
}
