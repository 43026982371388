import React from "react";
import "./index.css";

export default function Footer() {
  return (
    <div className="footerDiv">
      <span>Yatırım tavsiyesi değildir.</span>
    </div>
  );
}
