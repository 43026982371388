import { createContext, useState } from "react";

const themes = {
  dark: {
    backgroundColor: "#2c2f33",
    color: "#fff",
  },
  light: {
    backgroundColor: "#efefef",
    color: "#2c2f33",
  },
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(true);

  const theme = isDark ? themes.dark : themes.light;

  const toggleTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <ThemeContext.Provider value={[{ theme, isDark }, toggleTheme]}>
      {children}
    </ThemeContext.Provider>
  );
};
